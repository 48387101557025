import {Offer} from '../../../../interfaces';
import {Endpoints} from '../../../../constants';
import {OfferDto, OfferMap,} from '../../../../models';
import {addFilters} from '../../../../utils';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/offer`;
const map = new OfferMap();

export const apiOffer = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<OfferDto[], object | void>({
      query: (filter) => (filter ? addFilters(endpoint, filter) : endpoint),
      transformResponse: (response: Offer[]) => map.collectionToDto(response),
      providesTags: (result) => (result
        ? [
          ...result.map(({id}) => ({
            type: 'Offer' as const,
            id,
          })),
          'Offer',
        ]
        : ['Offer']),
    }),
    addOffer: builder.mutation<OfferDto, Partial<OfferDto>>({
      query(body) {
        return {
          url: endpoint,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: Offer) => map.toDto(response),

      // Invalidates all Offer-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Offers could show up in any lists.
      invalidatesTags: ['Offer'],
    }),
    getOffer: builder.query<OfferDto, string>({
      query: (id) => `${endpoint}/${id}`,
      providesTags: (result, error, id) => [{type: 'Offer', id}],
      transformResponse: (response: Offer) => map.toDto(response),
    }),
    updateOffer: builder.mutation<
      OfferDto,
      Partial<OfferDto>
    >({
      query(data) {
        return {
          url: `${endpoint}`,
          method: 'PUT',
          body: data,
        };
      },
      transformResponse: (response: Offer) => map.toDto(response),

      // Invalidates all queries that subscribe to this Offers `id` only.
      // In this case, `getOffers` will be re-run. `getOffers` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, {id}) => [
        {type: 'Offer', id},
        'Offer',
      ],
    }),
    deleteOffer: builder.mutation<
      { success: boolean; id: string },
      string
    >({
      query(id) {
        return {
          url: `${endpoint}/${id}`,
          method: 'DELETE',
        };
      },

      // Invalidates all queries that subscribe to this Offer `id` only.
      invalidatesTags: (result, error, id) => [
        {type: 'Offer', id},
        'Offer',
      ],
    }),
  }),
});

export const {
  useGetOffersQuery,
  useDeleteOfferMutation,
  useAddOfferMutation,
  useUpdateOfferMutation,
  useGetOfferQuery,
} = apiOffer;
