import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import navigation from "../../core/constants/navigations";
import {useLocation, useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";
import {useDispatch, useSelector} from 'react-redux';
import {getAuthSelector, setAuth} from "../../core/store/slice";
import Icon from "../../components/icon/icon";
import {ClickAwayListener, Grow, MenuList, Paper, Popper} from "@mui/material";
import {AppRoutes} from '../../core/constants';
import {useLogoutMutation} from "../../core/api";

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

interface Props {
}

const Header = (props: Props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const auth = useSelector(getAuthSelector);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [logoutStart] = useLogoutMutation()

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const goTo = (path: string) => {
        handleCloseNavMenu();
        navigate(path);
    }
    const toProfile = () => {
        navigate(AppRoutes.profile)
        handleCloseUserMenu()
    }
    const logout = () => {
        logoutStart({username: auth?.username}).unwrap().then(() => {
            dispatch(setAuth(undefined))
            handleCloseUserMenu()

        });
    }
    const isActive = (path: string) => {
        return location.pathname === path;
    }
    const isHomePage = location.pathname === "/";
    const isAuthPage = location.pathname.includes("/auth");

    return (
        <AppBar position="absolute"
                style={{background: "transparent", height: "100px", justifyContent: "center", display: isAuthPage ? "none": "flex"}}>
            <Box sx={{
                position: 'absolute',
                bottom: '-15px',
                width: '100%',
                display: 'block',
                background: 'rgba(0, 0, 0, 0.5)',
                padding: '5px 0',
                color: 'white',
                textAlign: 'center',
                zIndex: -1,
            }}>
                شكرا لزيارتكم موقعنا. هذه النسخة لا زالت تحت التطوير, قريبا سيتم الاطلاق الرسمي.
            </Box>
            <Container maxWidth="md">
                <Toolbar disableGutters>
                    <Link width={'150px'} href='/' sx={{
                        display: {xs: 'none', md: 'flex'},
                    }}>
                        <img width={'100px'} alt='vila'
                             src={isHomePage ? '/images/vilaMina/logoWhite.svg' : '/images/vilaMina/logo-yellow.svg'}/>
                    </Link>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color={isHomePage ? "inherit" : "secondary"}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {navigation.map((page) => (
                                <MenuItem key={page.path} onClick={() => goTo(page.path)} sx={
                                    {
                                        backgroundColor: isActive(page.path) ? '#C38F1B' : 'transparent'
                                    }

                                }>
                                    <Typography color={isActive(page.path) ? 'white' : '#075352'} textAlign="center">{page.title}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Link href='/' sx={{
                        display: {xs: 'flex', md: 'none'},
                    }}>
                        <img width={'90%'} alt='vila'
                             src={isHomePage ? '/images/vilaMina/logoWhite.svg' : '/images/vilaMina/logo-yellow.svg'}/>
                    </Link>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'space-between'}}>
                        {navigation.map((page) => (
                            <Button
                                key={page.path}
                                onClick={() => goTo(page.path)}
                                sx={{
                                    my: 2,
                                    color: isHomePage || isActive(page.path) ? 'white' : '#075352',
                                    display: 'block',
                                    backgroundColor: isActive(page.path) ? '#C38F1B' : 'transparent',
                                    "&:hover": {backgroundColor: '#C38F1B'}
                                }}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>

                    <Box display={"flex"} justifyContent={"end"} sx={{flexGrow: 0}}>
                        {!auth ? <Link href='/auth/login'>
                                <Button className="registerBtn border-radius-15" fullWidth>
                                    <Typography textAlign={'center'} className={'font-regular text-white fs-16'}> سجل
                                        دخول </Typography>
                                </Button>
                            </Link>
                            :
                            <Box pr={'15px'}>
                                <Button
                                    startIcon={<Icon icon="User" size={22} className={"text-white"}/>}
                                    endIcon={<Icon icon="chevron-down" size={10} className={"text-white"}/>}
                                    ref={anchorRef}
                                    id="composition-button"
                                    className='primary-grad-bg border-radius-12 h-45'
                                    aria-controls={anchorElUser ? 'composition-menu' : undefined}
                                    aria-expanded={anchorElUser ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleOpenUserMenu}
                                >
                                    <span className={'px-3 text-white'}>{auth?.data?.name}</span>
                                </Button>
                                <Popper
                                    open={!!anchorElUser}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    placement="bottom-start"
                                    transition
                                    disablePortal
                                >
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleCloseUserMenu}>
                                                    <MenuList
                                                        autoFocusItem={!!anchorElUser}
                                                        id="composition-menu"
                                                        aria-labelledby="composition-button"
                                                    >
                                                        <MenuItem onClick={toProfile}>حسابي</MenuItem>
                                                        <MenuItem onClick={logout}>تسجيل الخروج</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Box>
                        }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;