import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Container from "./container/Container";
import {Fab} from "@mui/material";
import ArrowUp from 'mdi-material-ui/ArrowUp'
import ScrollToTop from '../components/scroll-to-top'

export function Layout() {
    return <>
        <Header />
        <Container>
        <Outlet />
        </Container>
        <ScrollToTop className='mui-fixed'>
            <Fab color='primary' size='small' aria-label='scroll back to top'>
                <ArrowUp />
            </Fab>
        </ScrollToTop>
        <Footer />
    </>;
}

export default Layout;