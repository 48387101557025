export function getEnumKeyByEnumValue(
  myEnum: any,
  enumValue: number | string,
): string {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);

  return keys.length > 0 ? keys[0] : '';
}

export enum EventStatus {
  'pending' = 'pending',
  'confirmed' = 'confirmed',
  'rejected' = 'rejected',
  'Finished' = 'Finished',
}

export enum OfferStatus {
  'active' = 'active',
  'inactive' = 'inactive',
}
export enum ModalApp {
  infoSuccess = "infoSuccess",
  termsOfUse = "termsOfUse",
  directPayment = "directPayment",
  bankPayment = "bankPayment",
  paypalPayment = "paypalPayment",
}
