import { Person } from '../../../../interfaces';
import { Endpoints } from '../../../../constants';
import { PersonDto, PersonMap } from '../../../../models';
import { addFilters } from '../../../../utils';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/person`;
const map = new PersonMap();

export const apiPerson = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersons: builder.query<PersonDto[], object | void>({
      query: (filter) => (filter ? addFilters(endpoint, filter) : endpoint),
      transformResponse: (response: Person[]) => map.collectionToDto(response),
      providesTags: (result) => (result
        ? [
          ...result.map(({ id }) => ({
            type: 'Person' as const,
            id,
          })),
          'Person',
        ]
        : ['Person']),
    }),
    addPerson: builder.mutation<PersonDto, Partial<PersonDto>>({
      query(body) {
        return {
          url: endpoint,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: Person) => map.toDto(response),
      // Invalidates all Person-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Persons could show up in any lists.
      invalidatesTags: ['Person'],
    }),
    getPerson: builder.query<PersonDto, string>({
      query: (id) => `${endpoint}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Person', id }],
      transformResponse: (response: Person) => map.toDto(response),
    }),
    updatePerson: builder.mutation<
    PersonDto,
    Partial<PersonDto>
    >({
      query(data) {
        const { id } = data;
        return {
          url: `${endpoint}/${id}`,
          method: 'PUT',
          body: data,
        };
      },
      transformResponse: (response: Person) => map.toDto(response),
      // Invalidates all queries that subscribe to this Persons `id` only.
      // In this case, `getPersons` will be re-run. `getPersons` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Person', id },
        'Person',
      ],
    }),
    deletePerson: builder.mutation<
    { success: boolean; id: string },
    string
    >({
      query(id) {
        return {
          url: `${endpoint}/${id}`,
          method: 'DELETE',
        };
      },
      // Invalidates all queries that subscribe to this Person `id` only.
      invalidatesTags: (result, error, id) => [
        { type: 'Person', id },
        'Person',
      ],
    }),
  }),
});

export const {
  useGetPersonsQuery,
  useDeletePersonMutation,
  useAddPersonMutation,
  useUpdatePersonMutation,
  useGetPersonQuery,
} = apiPerson;
