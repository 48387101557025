import {IState} from './state';

export const getPersonSelector = (state: IState) => state.common.person;
export const getAuthSelector = (state: IState) => state.common.auth;
export const getAuthAdminSelector = (state: IState) => state.common.authAdmin;
export const getOldPathSelector = (state: IState) => state.common.oldPath;
export const getOtpDataSelector = (state: IState) => state.common.otpData;
export const getFilterRentalSelector = (state: IState) => state.common.filterRental;
export const getEventSelector = (state: IState) => state.common.event;
export const getOfferSelector = (state: IState) => state.common.offer;
export const getModalSelector = (state: IState) => state.common.modal;
