import {
  AuthData,
  AuthDto, EventDto, OfferDto, OtpData,
  PersonDto,
} from '../../../models';
import {IFilterRental} from "../../../interfaces/common";
import {ModalApp} from "../../../enum";

export const name = 'common';

export interface IState {
  common: CommonState;
}

export interface CommonState {
  person?: PersonDto;
  auth?: AuthDto;
  authAdmin?: AuthDto;
  otpData?: OtpData;
  oldPath?: string;
  filterRental?: IFilterRental;
  event?: EventDto;
  offer?: OfferDto;
  modal?: ModalApp;

}
