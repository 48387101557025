import moment from 'moment';
import 'moment/locale/ar-tn';

moment.locale('ar-tn');
export const DateFormater = (
  value: string | Date,
  format = 'DD/MM/YYYY',
): string => {
  return value ? moment(value).format(format) : '';
};
export default DateFormater;
