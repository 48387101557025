import { Setting } from '../../../../interfaces';
import { Endpoints } from '../../../../constants';
import {SettingDto, SettingMap,} from '../../../../models';
import { addFilters } from '../../../../utils';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/setting`;
const map = new SettingMap();

export const apiSetting = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<SettingDto, object | void>({
      query: (filter) => (filter ? addFilters(endpoint, filter) : endpoint),
      transformResponse: (response: Setting) => map.toDto(response),
      providesTags: (result) => ( ['Setting']),
    }),
    addSetting: builder.mutation<SettingDto, Partial<SettingDto>>({
      query(body) {
        return {
          url: endpoint,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: Setting) => map.toDto(response),

      // Invalidates all Setting-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Settings could show up in any lists.
      invalidatesTags: ['Setting'],
    }),
    getSetting: builder.query<SettingDto, string>({
      query: (id) => `${endpoint}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Setting', id }],
      transformResponse: (response: Setting) => map.toDto(response),
    }),
    updateSetting: builder.mutation<
    SettingDto,
    any
    >({
      query(data) {

return {
          url: `${endpoint}`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: Setting) => map.toDto(response),

      // Invalidates all queries that subscribe to this Settings `id` only.
      // In this case, `getSettings` will be re-run. `getSettings` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Setting', id },
        'Setting',
      ],
    }),
    deleteSetting: builder.mutation<
    { success: boolean; id: string },
    string
    >({
      query(id) {
        return {
          url: `${endpoint}/${id}`,
          method: 'DELETE',
        };
      },

      // Invalidates all queries that subscribe to this Setting `id` only.
      invalidatesTags: (result, error, id) => [
        { type: 'Setting', id },
        'Setting',
      ],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useDeleteSettingMutation,
  useAddSettingMutation,
  useUpdateSettingMutation,
  useGetSettingQuery,
} = apiSetting;
