import { Setting } from '../../interfaces/business';
import { ApiResponseMap } from '../apiResponse';

export interface SettingDto extends Setting {
  entity: string;
  iri: string;
}
export type SettingData = SettingDto



export class SettingMap extends ApiResponseMap {
  public toDto(item: Setting): SettingDto {
    return super.toDto(item) as SettingDto;
  }

  public collectionToDto(
    items: Setting[],
  ): SettingDto[] {
    return super.collectionToDto(items) as SettingDto[];
  }
}
