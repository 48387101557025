import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./layout/Layout";
import 'react-perfect-scrollbar/dist/css/styles.css'
import "./assets/globals.scss"
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import {lazy, Suspense} from "react";
import RoutePermission from "./components/route-permission/routePermission";
import Loading from "./components/loading/Loading";

const Home = lazy(() => import("./pages/home/Home"));
const AppAuth = lazy(() => import("./pages/auth/AppAuth"));
const About = lazy(() => import("./pages/about"));
const Contact = lazy(() => import("./pages/contact"));
const Book = lazy(() => import("./pages/book"));
const Gallery = lazy(() => import("./pages/gallery"));
const Offers = lazy(() => import("./pages/offers"));
const Profile = lazy(() => import("./pages/profile"));
const Evaluation = lazy(() => import("./pages/evaluation"));
const Terms = lazy(() => import("./pages/terms"));

export function AppRoutes() {
  return (
    <BrowserRouter>

      <Routes>
        <Route
          path={`/auth/*`}
          element={
            <Suspense fallback={<Loading type={"page"}/>}>
              <AppAuth/>{" "}
            </Suspense>
          }
        />
        <Route
          path="/*"
          element={
            <Layout/>
          }
        >
          <Route index element={<Suspense fallback={<Loading type={"page"}/>}><Home/></Suspense>}/>
          <Route path="about" element={<Suspense fallback={<Loading type={"page"}/>}><About/></Suspense>}/>
          <Route path="contact"
                 element={<Suspense fallback={<Loading type={"page"}/>}><Contact/></Suspense>}/>
          <Route path="book" element={<Suspense
            fallback={<Loading type={"page"}/>}><RoutePermission><Book/></RoutePermission></Suspense>}/>
          <Route path="gallery"
                 element={<Suspense fallback={<Loading type={"page"}/>}><Gallery/></Suspense>}/>
          <Route path="offers"
                 element={<Suspense fallback={<Loading type={"page"}/>}><Offers/></Suspense>}/>
          <Route path="profile" element={<Suspense fallback={
            <Loading type={"page"}/>}><RoutePermission><Profile/></RoutePermission></Suspense>}/>
          <Route path="evaluation/:id" element={<Suspense fallback={<Loading type={"page"}/>}><Evaluation/></Suspense>}/>
        <Route path="terms/:id" element={<Suspense fallback={<Loading type={"page"}/>}><Terms/></Suspense>}/>
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default AppRoutes;