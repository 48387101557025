import { ApiResponse } from '../interfaces';

export interface ApiResponseDto extends ApiResponse {
  entity: string;
  iri: string;
  id: string;
}

export class ApiResponseMap {
  public toPersistence(item: ApiResponseDto): ApiResponse {
    return {
      ...item,
    };
  }

  public toDto(item: ApiResponse): ApiResponseDto {
    return {
      ...item,
      entity: item['@type'] ?? '',
      iri: item['@id'] ?? '',
    };
  }

  public collectionToDto(
    collection: ApiResponse[],
  ): ApiResponseDto[] {
    return collection.map((item) => this.toDto(item));
  }
}
