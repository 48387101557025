import { isArray } from 'lodash';

export const createUrl = (url: string): URL => {
  let newUrl;
  try {
    newUrl = new URL(url);
  } catch (e) {
    newUrl = new URL(url, origin);
  }

  return newUrl;
};

export const addFilters = (url: string, filters: object): string => {
  const newUrl = createUrl(url);

  for (let i = 0; i < Object.entries(filters).length; i += 1) {
    const [filter, value] = Object.entries(filters)[i];
    if (value !== null && value !== undefined) {
      if (isArray(value)) {
        value.forEach((val) => newUrl.searchParams.append(filter, val));
      } else {
        newUrl.searchParams.append(filter, value);
      }
    }
  }

  return newUrl.toString();
};
