import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { handelError } from '../../utils';

const customMiddleWare: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!');
    console.log({ action });
    handelError(action.payload);
  }
  next(action);
};
export default customMiddleWare;
