const AppRoutes = {
  create: 'create',
  person: 'person',
  register: '/auth/register',
  auth: '/auth',
  login: '/auth/login',
  home: '/',
  otp: '/auth/otp',
  forgot: '/auth/forgot-password',
  resetPasword: '/auth/reset-password',
  about: '/about',
  offers: '/offers',
  gallery: '/gallery',
  contact: '/contact',
  book: '/book',
  profile: '/profile',
  admin: {
    home: '/admin/home',
    event: '/admin/requests',
    setting: '/admin/setting',
    login: '/admin/login',
    offer: {
      create: '/admin/offer/create',
      list: '/admin/offer',
    },
  }
};
export default AppRoutes;
