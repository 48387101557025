import { configureStore } from '@reduxjs/toolkit';
import { apiPerson, apiSlice } from '../api/rtk';
import customMiddleWare from './middleware/customMiddleware';
import { commonSlice } from './slice';
import StateLoader from './stateLoader';

export const store = configureStore({
  reducer: {
    [apiPerson.reducerPath]: apiPerson.reducer,
    [commonSlice.name]: commonSlice.reducer,
  },
  preloadedState: StateLoader.loadState(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(apiSlice.middleware, customMiddleWare),
});
store.subscribe(() => {
  StateLoader.saveState(store.getState());
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
