import { Endpoints } from '../../../../constants';
import {
  AuthData,
  AuthDto, AuthForgotPasswordData,
  AuthRegisterData,
  AuthUpdatePasswordData,
} from '../../../../models';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/auth`;
export const apiAuth = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthDto, AuthData>({
      query(body) {
        return {
          url: `${endpoint}/login`,
          method: 'POST',
          body,
        };
      },
    }),
   logout: builder.mutation<AuthData, any>({
      query(body) {
        return {
          url: `${endpoint}/logout`,
          method: 'POST',
          body,
        };
      },
    }),
    verifLogin: builder.mutation<AuthDto, AuthData>({
      query(body) {
        return {
          url: `${endpoint}/verif-login`,
          method: 'POST',
          body,
        };
      },
    }),
    forgot: builder.mutation<AuthDto, AuthData>({
      query(body) {
        return {
          url: `${endpoint}/forgot`,
          method: 'POST',
          body,
        };
      },
    }),
    verifForgot: builder.mutation<AuthDto, AuthData>({
      query(body) {
        return {
          url: `${endpoint}/verif-forgot`,
          method: 'POST',
          body,
        };
      },
    }),
    refreshToken: builder.mutation<AuthDto, AuthDto>({
      query(body) {
        return {
          url: `${endpoint}/refresh`,
          method: 'POST',
          body,
        };
      },
    }),
    register: builder.mutation<string, AuthRegisterData>({
      query(body) {
        return {
          url: `${endpoint}/register`,
          method: 'POST',
          body,
        };
      },
    }),
    register2: builder.mutation<string, any>({
      query(body) {
        return {
          url: `${endpoint}/register-notif`,
          method: 'POST',
          body,
        };
      },
    }),
    updatePassword: builder.mutation<string, AuthUpdatePasswordData>({
      query(body) {
        const { id, ...data } = body;

return {
          url: `${endpoint}/update-password`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    updateForgotPassword: builder.mutation<string, AuthForgotPasswordData>({
      query(body) {

        return {
          url: `${endpoint}/update-password`,
          method: 'POST',
          body,
        };
      }

    }
    ),
    loginAdmin: builder.mutation<AuthDto, AuthData>({
      query(body) {
        return {
          url: `${endpoint}/login-admin`,
          method: 'POST',
          body,
        };
      },
    }),
    logoutAdmin: builder.mutation<AuthData, void>({
      query() {
        return {
          url: `${endpoint}/logout-admin`,
          method: 'POST',
          body:{},
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useRegisterMutation,
  useRegister2Mutation,
  useUpdatePasswordMutation,
  useUpdateForgotPasswordMutation,
  useVerifLoginMutation,
  useVerifForgotMutation,
  useForgotMutation,
  useLogoutMutation,
  useLoginAdminMutation,
  useLogoutAdminMutation,
} = apiAuth;
