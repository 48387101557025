import AppRoutes from "./appRoutes";

const navigation =  [
        {
            title: 'الرئيسية',
            path: `${AppRoutes.home}`
        },
        {
            title: 'عن فيلا مينا',
            path: `${AppRoutes.about}`
        },
        {
            title: 'العروض',
            path: `${AppRoutes.offers}`,
        },
        {
            title: 'معرض الصور',
            path: `${AppRoutes.gallery}`,
        },
        {
            title: 'تواصل معنا',
            path: `${AppRoutes.contact}`,
        },
    ]

export default navigation
