// @ts-ignore
import IcoMoon, { IconComponent } from "react-icomoon";
const iconSet = require("../../assets/icomoon/selection.json");


const Icon: typeof IconComponent = (props: any) => (
  <IcoMoon iconSet={iconSet} {...props} />
);

export default Icon;
