import {BaseQueryFn, createApi} from '@reduxjs/toolkit/query/react';
import {fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';
import {BaseQueryResult} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {setAuth} from '../../../store/slice';
import {Endpoints} from '../../../constants';
import {IState} from '../../../store/slice/common/state';

let refresh: any;

const query = fetchBaseQuery({
  baseUrl: '',
  prepareHeaders: (headers, {getState}) => {
    const {auth} = (getState() as IState).common;
    if (auth?.token) {
      headers.set('authorization', `Bearer ${auth.token}`);
    }

    return headers;
  },
});
const getRefresh: BaseQueryFn = (args, api, extraOptions) => {
  if (!refresh) {
    refresh = query(args, api, extraOptions);
  }

  return refresh;
};
const baseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  const {auth} = (api.getState() as IState).common;
  let result = await query(args, api, extraOptions);
  if (auth?.token && result.error && result.error.status === 401) {
    const refreshResult: BaseQueryResult<any> = await getRefresh(
      {
        url: `${Endpoints.business}/api/token/refresh`,
        method: 'POST',
        body: {refresh_token: auth.refreshToken},
      },
      api,
      extraOptions,
    );
    if (refreshResult?.data) {
      const {refreshToken} = refreshResult.data;
      const {token} = refreshResult.data;

      // store the new token
      api.dispatch(setAuth({...auth,token, refreshToken} as any));

      // retry the initial query
      result = await query(args, api, extraOptions);
    } else {
      // logged out the user
      api.dispatch(setAuth(undefined));
    }
    refresh = undefined;
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'Person',
    'Event',
    'Comment',
    'Contact',
    'User',
    'Evaluation',
    'Setting',
    'Offer',
    'Content',
  ],
  endpoints: () => ({}),
});

export default apiSlice;
