import { Person } from '../../interfaces/business';
import { ApiResponseMap } from '../apiResponse';

export interface PersonDto extends Person {
  entity: string;
  iri: string;
}

export class PersonMap extends ApiResponseMap {
  public toDto(item: Person): PersonDto {
    return super.toDto(item);
  }

  public collectionToDto(
    items: Person[],
  ): PersonDto[] {
    return super.collectionToDto(items);
  }
}
