import {createSlice} from '@reduxjs/toolkit';
import {reducers} from './reducers';
import {CommonState, name} from './state';

const initialState = {} as CommonState;

export const commonSlice = createSlice({
    name,
    initialState: initialState as any,
    reducers,
});

export const {
    setPerson,
    setAuth,
    setAuthAdmin,
    setOldPath,
    setOtpData,
    setFilterRental,
    setEvent,
    setOffer,
    setModal,
} = commonSlice.actions;
export * from './selectors';
