const storedState = ['common'];
const key = 'Tree';
class StateLoader {
  static loadState() {
    try {
      const serializedState = localStorage.getItem(key);

      if (serializedState === null) {
        return this.initializeState();
      }

      return this.filterState(JSON.parse(serializedState));
    } catch (err) {
      return this.initializeState();
    }
  }

  static filterState(state: any) {
    const result: any = {};
    storedState.forEach((item) => {
      result[item] = state[item];
    });
    return result;
  }

  static saveState(state: any) {
    try {
      const serializedState = JSON.stringify(this.filterState(state));
      localStorage.setItem(key, serializedState);
    } catch (err) {
      localStorage.setItem(key, JSON.stringify(this.initializeState()));
    }
  }

  static initializeState() {
    return {};
  }
}
export default StateLoader;
