import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import React, {FC} from 'react';
import {Box} from "@mui/material";

interface LoadingProps {
    type?: 'page' | 'section';
}

const Loading: FC<LoadingProps> = ({type}) =>
    (type === 'page' ?
            <Box height={"100vh"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{background:"#3a354133"}} >
                <CircularProgress color="inherit"/>
            </Box>
            :
            <Box className="d-flex justify-content-center">
                <CircularProgress color="inherit"/>
            </Box>
    )

export default Loading;