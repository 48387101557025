import { Evaluation } from '../../../../interfaces';
import { Endpoints } from '../../../../constants';
import {EvaluationDto, EvaluationMap,} from '../../../../models';
import { addFilters } from '../../../../utils';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/evaluation`;
const map = new EvaluationMap();

export const apiEvaluation = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvaluations: builder.query<EvaluationDto[], object | void>({
      query: (filter) => (filter ? addFilters(`${endpoint}/my-evaluations`, filter) : `${endpoint}/my-evaluations`),
      transformResponse: (response: Evaluation[]) => map.collectionToDto(response),
      providesTags: (result) => (result
        ? [
          ...result.map(({ id }) => ({
            type: 'Evaluation' as const,
            id,
          })),
          'Evaluation',
        ]
        : ['Evaluation']),
    }),
    addEvaluation: builder.mutation<EvaluationDto, Partial<EvaluationDto>>({
      query(body) {
        return {
          url: endpoint,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: Evaluation) => map.toDto(response),

      // Invalidates all Evaluation-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Evaluations could show up in any lists.
      invalidatesTags: ['Evaluation'],
    }),
    getEvaluation: builder.query<EvaluationDto, string>({
      query: (id) => `${endpoint}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Evaluation', id }],
      transformResponse: (response: Evaluation) => map.toDto(response),
    }),
    updateEvaluation: builder.mutation<
    EvaluationDto,
    Partial<EvaluationDto>
    >({
      query(data) {
        const { id } = data;

return {
          url: `${endpoint}/${id}`,
          method: 'PUT',
          body: data,
        };
      },
      transformResponse: (response: Evaluation) => map.toDto(response),

      // Invalidates all queries that subscribe to this Evaluations `id` only.
      // In this case, `getEvaluations` will be re-run. `getEvaluations` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Evaluation', id },
        'Evaluation',
      ],
    }),
    deleteEvaluation: builder.mutation<
    { success: boolean; id: string },
    string
    >({
      query(id) {
        return {
          url: `${endpoint}/${id}`,
          method: 'DELETE',
        };
      },

      // Invalidates all queries that subscribe to this Evaluation `id` only.
      invalidatesTags: (result, error, id) => [
        { type: 'Evaluation', id },
        'Evaluation',
      ],
    }),
  }),
});

export const {
  useGetEvaluationsQuery,
  useDeleteEvaluationMutation,
  useAddEvaluationMutation,
  useUpdateEvaluationMutation,
  useGetEvaluationQuery,
} = apiEvaluation;
