import {Draft, PayloadAction} from '@reduxjs/toolkit';
import {AuthData, AuthDto, EventDto, OfferDto, OtpData, PersonDto} from '../../../models';
import {CommonState} from './state';
import {IFilterRental} from "../../../interfaces/common";
import {ModalApp} from "../../../enum";

export const reducers = {
  setPerson: (
    state: Draft<CommonState>,
    action: PayloadAction<PersonDto>,
  ) => ({...state, person: action.payload}),
  setAuth: (
    state: Draft<CommonState>,
    action: PayloadAction<AuthDto | undefined>,
  ) => ({
    ...state,
    auth: action.payload,
  }),
  setAuthAdmin: (
    state: Draft<CommonState>,
    action: PayloadAction<AuthDto | undefined>,
  ) => ({
    ...state,
    authAdmin: action.payload,
  }),
  setOldPath: (
    state: Draft<CommonState>,
    action: PayloadAction<string | undefined>,
  ) => ({
    ...state,
    oldPath: action.payload,
  }),
  setOtpData: (
    state: Draft<CommonState>,
    action: PayloadAction<OtpData | undefined>,
  ) => ({
    ...state,
    otpData: action.payload,
  }),
  setFilterRental: (
    state: Draft<CommonState>,
    action: PayloadAction<IFilterRental | undefined>,
  ) => ({
    ...state,
    filterRental: action.payload,
  }),
  setEvent: (
    state: Draft<CommonState>,
    action: PayloadAction<EventDto | undefined>,
  ) => ({
    ...state,
    event: action.payload,
  }),
  setOffer: (
    state: Draft<CommonState>,
    action: PayloadAction<OfferDto | undefined>,
  ) => ({
    ...state,
    offer: action.payload,
  }),
    setModal: (
        state: Draft<CommonState>,
        action: PayloadAction<ModalApp | undefined>,
    ) => ({
        ...state,
        modal: action.payload,
    }),
};
