import { Event } from '../../interfaces/business';
import { ApiResponseMap } from '../apiResponse';

export interface EventDto extends Event {
  entity: string;
  iri: string;
}
export type EventData = EventDto

export interface PaymentBankEventData {
  paymentFile: string;
  eventId: string;
}
export interface PaymentDirectEventData {
  paymentFile: string;
  eventId: string;
}


export class EventMap extends ApiResponseMap {
  public toDto(item: Event): EventDto {
    return super.toDto(item) as EventDto;
  }

  public collectionToDto(
    items: Event[],
  ): EventDto[] {
    return super.collectionToDto(items) as EventDto[];
  }
}
