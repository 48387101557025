import { User } from '../../../../interfaces';
import { Endpoints } from '../../../../constants';
import {UserDto, UserMap,} from '../../../../models';
import { addFilters } from '../../../../utils';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/user`;
const map = new UserMap();

export const apiUser = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<UserDto[], object | void>({
      query: (filter) => (filter ? addFilters(endpoint, filter) : endpoint),
      transformResponse: (response: User[]) => map.collectionToDto(response),
      providesTags: (result) => (result
        ? [
          ...result.map(({ id }) => ({
            type: 'User' as const,
            id,
          })),
          'User',
        ]
        : ['User']),
    }),
    addUser: builder.mutation<UserDto, Partial<UserDto>>({
      query(body) {
        return {
          url: endpoint,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: User) => map.toDto(response),

      // Invalidates all User-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Users could show up in any lists.
      invalidatesTags: ['User'],
    }),
    getUser: builder.query<UserDto, string>({
      query: (id) => `${endpoint}/profile`,
      providesTags: (result, error, id) => [{ type: 'User', id }],
      transformResponse: (response: User) => map.toDto(response),
    }),
    updateUser: builder.mutation<
    UserDto,
    Partial<UserDto>
    >({
      query(data) {
return {
          url: `${endpoint}/profile`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: User) => map.toDto(response),

      // Invalidates all queries that subscribe to this Users `id` only.
      // In this case, `getUsers` will be re-run. `getUsers` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'User', id },
        'User',
      ],
    }),
    deleteUser: builder.mutation<
    { success: boolean; id: string },
    string
    >({
      query(id) {
        return {
          url: `${endpoint}/${id}`,
          method: 'DELETE',
        };
      },

      // Invalidates all queries that subscribe to this User `id` only.
      invalidatesTags: (result, error, id) => [
        { type: 'User', id },
        'User',
      ],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useDeleteUserMutation,
  useAddUserMutation,
  useUpdateUserMutation,
  useGetUserQuery,
} = apiUser;
