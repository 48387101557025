import {EventStatus, OfferStatus} from "../enum";

const enumLabel: any = {
  payment2: 'دفع كاش',
  payment0: 'دفع حوالة',
  payment1: 'دفع بطاقة',
  [OfferStatus.active]: 'فعال',
  [OfferStatus.inactive]: 'غير فعال',
  [EventStatus.confirmed]: 'مؤكد',
  [EventStatus.pending]: ' انتظار',
  [EventStatus.rejected]: 'مرفوض',
  [EventStatus.Finished]: 'منتهي',
};
export default enumLabel;
