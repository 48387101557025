import React, { PropsWithChildren} from "react";
import { useSelector } from "react-redux";
import { getAuthSelector } from "../../core/store/slice";
import { Navigate, Outlet} from "react-router-dom";

/**
 * Route check user permission
 */
export interface Props extends PropsWithChildren {
}

export function RoutePermission({ children }: Props) {
    const auth = useSelector(getAuthSelector);
    return auth ? (
        <div data-testid="allow">{children || <Outlet />}</div>
    ) : (
        <Navigate to={"/auth"} />
    );
}
export default RoutePermission;
