import { Contact } from '../../interfaces/business';
import { ApiResponseMap } from '../apiResponse';

export interface ContactDto extends Contact {
  entity: string;
  iri: string;
}
export type ContactData = ContactDto



export class ContactMap extends ApiResponseMap {
  public toDto(item: Contact): ContactDto {
    return super.toDto(item) as ContactDto;
  }

  public collectionToDto(
    items: Contact[],
  ): ContactDto[] {
    return super.collectionToDto(items) as ContactDto[];
  }
}
