import { Evaluation } from '../../interfaces/business';
import { ApiResponseMap } from '../apiResponse';

export interface EvaluationDto extends Evaluation {
  entity: string;
  iri: string;
}
export type EvaluationData = EvaluationDto



export class EvaluationMap extends ApiResponseMap {
  public toDto(item: Evaluation): EvaluationDto {
    return super.toDto(item) as EvaluationDto;
  }

  public collectionToDto(
    items: Evaluation[],
  ): EvaluationDto[] {
    return super.collectionToDto(items) as EvaluationDto[];
  }
}
