import {Box, Link, Typography} from '@mui/material';
import React, { FC } from 'react';
import Icon from "../../components/icon/icon";
import {useGetSettingsQuery} from "../../core/api";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
    const {data: setting} = useGetSettingsQuery();
    const aboutContent = setting?.aboutContent || {};
    return (
        <Box className={'footer'}
             sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-around'}}>
            <Box className={'footer-content'}>
                <Box className={'footer-logo'} px={3}>
                    <img alt='logo' src='/images/vilaMina/logo-white-sm.svg'/>
                </Box>
                <Box color={'white'}>
                    <Typography className={'font-regular fs-16'} color={'white'} sx={{mr: 2}}>
                        فيلا مينا ® {new Date().getFullYear()}. جميع الحقوق محفوظة.
                    </Typography>
                    <Box>
          <span className={"font-regular fs-13 px-3"}>
            حجوزات خدمة العملاء
          </span>
                        <span className={'custom-number font-regular fs-16'}>  {aboutContent.phone}</span>
                    </Box>
                </Box>
            </Box>
            <Box className={'onlyWeb'} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} pt={2}>
                <Link href={aboutContent.youtube} px={2}>
                    <Icon icon="youtube" size={30} className={"text-white"}/>
                </Link>
                <Link href={aboutContent.tiktok} px={2}>
                    <Icon icon="tiktok" size={30} className={"text-white"}/>
                </Link>
                <Link href={aboutContent.pinterest} px={2}>
                    <Icon icon="pinterest" size={30} className={"text-white"}/>
                </Link>
                <Link href={aboutContent.twitter} px={2}>
                    <Icon icon="twitter" size={30} className={"text-white"}/>
                </Link>
                <Link href={aboutContent.instagram} px={2}>
                    <Icon icon="instagram" size={30} className={"text-white"}/>
                </Link>

                <Link href={aboutContent.facebook} px={2}>
                    <Icon icon="facebook" size={30} className={"text-white"}/>
                </Link>


            </Box>
        </Box>
    )
};

export default Footer;
