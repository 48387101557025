import {Event} from '../../../../interfaces';
import {Endpoints} from '../../../../constants';
import {EventDto, EventMap, PaymentBankEventData, PaymentDirectEventData} from '../../../../models';
import {addFilters} from '../../../../utils';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/event`;
const endpointPayment = `${Endpoints.business}/api/payment`;
const endpointCoupon = `${Endpoints.business}/api/coupon`;
const map = new EventMap();

export const apiEvent = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query<EventDto[], object | void>({
            query: (filter) => (filter ? addFilters(`${endpoint}/user`, filter) : `${endpoint}/user`),
            transformResponse: (response: Event[]) => map.collectionToDto(response),
            providesTags: (result) => (result
                ? [
                    ...result.map(({id}) => ({
                        type: 'Event' as const,
                        id,
                    })),
                    'Event',
                ]
                : ['Event']),
        }),
        addEvent: builder.mutation<EventDto, Partial<EventDto>>({
            query(body) {
                return {
                    url: endpoint,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: Event) => map.toDto(response),

            // Invalidates all Event-type queries providing the `LIST` id - after all, depending of the sort order,
            // that newly created Events could show up in any lists.
            invalidatesTags: ['Event'],
        }),
        getEvent: builder.query<EventDto, string>({
            query: (id) => `${endpoint}/${id}`,
            providesTags: (result, error, id) => [{type: 'Event', id}],
            transformResponse: (response: Event) => map.toDto(response),
        }),
        updateEvent: builder.mutation<
            EventDto,
            Partial<EventDto>
        >({
            query(data) {
                const {id} = data;

                return {
                    url: `${endpoint}/${id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            transformResponse: (response: Event) => map.toDto(response),

            // Invalidates all queries that subscribe to this Events `id` only.
            // In this case, `getEvents` will be re-run. `getEvents` *might*  rerun, if this id was under its results.
            invalidatesTags: (result, error, {id}) => [
                {type: 'Event', id},
                'Event',
            ],
        }),
        addTerms: builder.mutation<
            EventDto,
            { id: string, identifierFile: string, signatureFile: string}
        >({
            query(body) {
                return {
                    url: `${endpoint}/terms`,
                    method: 'POST',
                    body,
                };
            },
        }),
        deleteEvent: builder.mutation<
            { success: boolean; id: string },
            string
        >({
            query(id) {
                return {
                    url: `${endpoint}/${id}`,
                    method: 'DELETE',
                };
            },

            // Invalidates all queries that subscribe to this Event `id` only.
            invalidatesTags: (result, error, id) => [
                {type: 'Event', id},
                'Event',
            ],
        }),
        addPaymentBankEvent: builder.mutation<EventDto, PaymentBankEventData>({
            query(body) {
                return {
                    url: endpointPayment + "/addPayment",
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: Event) => map.toDto(response),

            // Invalidates all Event-type queries providing the `LIST` id - after all, depending of the sort order,
            // that newly created Events could show up in any lists.
            invalidatesTags: ['Event'],
        }),
        addPaymentDirectEvent: builder.mutation<EventDto, PaymentDirectEventData>({
            query(body) {
                return {
                    url: endpointPayment + "/addPayment",
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: Event) => map.toDto(response),

            // Invalidates all Event-type queries providing the `LIST` id - after all, depending of the sort order,
            // that newly created Events could show up in any lists.
            invalidatesTags: ['Event'],
        }),
        getMyEvents: builder.query<EventDto[], object | void>({
            query: (filter) => (filter ? addFilters(`${endpoint}/myt`, filter) : `${endpoint}/my`),
            transformResponse: (response: Event[]) => map.collectionToDto(response),
            providesTags: (result) => (result
                ? [
                    ...result.map(({id}) => ({
                        type: 'Event' as const,
                        id,
                    })),
                    'Event',
                ]
                : ['Event']),
        }),
        createOrder: builder.mutation<any, any>({
                query(body) {
                    return {
                        url: endpointPayment + "/createOrder",
                        method: 'POST',
                        body,
                    };
                },
            }
        ),
        captureOrder: builder.mutation<any, any>({
                query(body) {
                    return {
                        url: endpointPayment + "/captureOrder",
                        method: 'POST',
                        body,
                    };
                },
            }
        ),
        getCoupon: builder.mutation<any, any>({
                query({id}) {
                    return  `${endpointCoupon}/${id}`;
                    }
                },
        ),
        calculate: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `${endpoint}/calculate`,
                    method: 'POST',
                    body: data,
                }
            }

        }),
    }),
});

export const {
    useGetEventsQuery,
    useDeleteEventMutation,
    useAddEventMutation,
    useUpdateEventMutation,
    useGetEventQuery,
    useAddPaymentBankEventMutation,
    useAddPaymentDirectEventMutation,
    useGetMyEventsQuery,
    useCaptureOrderMutation,
    useCreateOrderMutation,
    useGetCouponMutation,
  useCalculateMutation,
  useAddTermsMutation
} = apiEvent;
