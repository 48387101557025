import { Comment } from '../../interfaces/business';
import { ApiResponseMap } from '../apiResponse';

export interface CommentDto extends Comment {
  entity: string;
  iri: string;
}
export interface CommentData extends CommentDto {
}



export class CommentMap extends ApiResponseMap {
  public toDto(item: Comment): CommentDto {
    return super.toDto(item) as CommentDto;
  }

  public collectionToDto(
    items: Comment[],
  ): CommentDto[] {
    return super.collectionToDto(items) as CommentDto[];
  }
}
