import { Comment } from '../../../../interfaces';
import { Endpoints } from '../../../../constants';
import {CommentDto, CommentMap,} from '../../../../models';
import { addFilters } from '../../../../utils';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/comment`;
const map = new CommentMap();

export const apiComment = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query<CommentDto[], object | void>({
      query: (filter) => (filter ? addFilters(endpoint, filter) : endpoint),
      transformResponse: (response: Comment[]) => map.collectionToDto(response),
      providesTags: (result) => (result
        ? [
          ...result.map(({ id }) => ({
            type: 'Comment' as const,
            id,
          })),
          'Comment',
        ]
        : ['Comment']),
    }),
    addComment: builder.mutation<CommentDto, Partial<CommentDto>>({
      query(body) {
        return {
          url: endpoint,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: Comment) => map.toDto(response),

      // Invalidates all Comment-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Comments could show up in any lists.
      invalidatesTags: ['Comment'],
    }),
    getComment: builder.query<CommentDto, string>({
      query: (id) => `${endpoint}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Comment', id }],
      transformResponse: (response: Comment) => map.toDto(response),
    }),
    updateComment: builder.mutation<
    CommentDto,
    Partial<CommentDto>
    >({
      query(data) {
        const { id } = data;

return {
          url: `${endpoint}/${id}`,
          method: 'PUT',
          body: data,
        };
      },
      transformResponse: (response: Comment) => map.toDto(response),

      // Invalidates all queries that subscribe to this Comments `id` only.
      // In this case, `getComments` will be re-run. `getComments` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Comment', id },
        'Comment',
      ],
    }),
    deleteComment: builder.mutation<
    { success: boolean; id: string },
    string
    >({
      query(id) {
        return {
          url: `${endpoint}/${id}`,
          method: 'DELETE',
        };
      },

      // Invalidates all queries that subscribe to this Comment `id` only.
      invalidatesTags: (result, error, id) => [
        { type: 'Comment', id },
        'Comment',
      ],
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useDeleteCommentMutation,
  useAddCommentMutation,
  useUpdateCommentMutation,
  useGetCommentQuery,
} = apiComment;
