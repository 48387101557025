import { Contact } from '../../../../interfaces';
import { Endpoints } from '../../../../constants';
import {ContactDto, ContactMap,} from '../../../../models';
import { addFilters } from '../../../../utils';
import apiSlice from '../../api/apiSlice';

const endpoint = `${Endpoints.business}/api/contact`;
const map = new ContactMap();

export const apiContact = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<ContactDto[], object | void>({
      query: (filter) => (filter ? addFilters(endpoint, filter) : endpoint),
      transformResponse: (response: Contact[]) => map.collectionToDto(response),
      providesTags: (result) => (result
        ? [
          ...result.map(({ id }) => ({
            type: 'Contact' as const,
            id,
          })),
          'Contact',
        ]
        : ['Contact']),
    }),
    addContact: builder.mutation<ContactDto, Partial<ContactDto>>({
      query(body) {
        return {
          url: endpoint,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: Contact) => map.toDto(response),

      // Invalidates all Contact-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Contacts could show up in any lists.
      invalidatesTags: ['Contact'],
    }),
    getContact: builder.query<ContactDto, string>({
      query: (id) => `${endpoint}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Contact', id }],
      transformResponse: (response: Contact) => map.toDto(response),
    }),
    updateContact: builder.mutation<
    ContactDto,
    Partial<ContactDto>
    >({
      query(data) {
        const { id } = data;

return {
          url: `${endpoint}/${id}`,
          method: 'PUT',
          body: data,
        };
      },
      transformResponse: (response: Contact) => map.toDto(response),

      // Invalidates all queries that subscribe to this Contacts `id` only.
      // In this case, `getContacts` will be re-run. `getContacts` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Contact', id },
        'Contact',
      ],
    }),
    deleteContact: builder.mutation<
    { success: boolean; id: string },
    string
    >({
      query(id) {
        return {
          url: `${endpoint}/${id}`,
          method: 'DELETE',
        };
      },

      // Invalidates all queries that subscribe to this Contact `id` only.
      invalidatesTags: (result, error, id) => [
        { type: 'Contact', id },
        'Contact',
      ],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useDeleteContactMutation,
  useAddContactMutation,
  useUpdateContactMutation,
  useGetContactQuery,
} = apiContact;
