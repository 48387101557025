import { toast } from 'react-toastify';

const getError = (payload: any) => ({
  title: payload['hydra:title'] ?? 'Erreur',
  description: payload['hydra:description'] ?? 'Une erreur est survenue',
});
export const handelError = (payload: any) => {
  const error = getError(payload?.data ?? {});
  const show = (
    <div>
      <h3>{error.title}</h3>
      <p>{error.description}</p>
    </div>
  );
toast(show, { type: 'error'})};
export default handelError;
