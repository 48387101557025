import React, {FC, PropsWithChildren} from 'react';
import Box from "@mui/material/Box";

interface ContainerProps {}

const Container: FC<PropsWithChildren<ContainerProps>> = ({children}) => (
  <Box minHeight={"100vh"}>
      {children}
  </Box>
);

export default Container;
