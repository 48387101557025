import { User } from '../../interfaces/business';
import { ApiResponseMap } from '../apiResponse';

export interface UserDto extends User {
  entity: string;
  iri: string;
}
export type UserData = UserDto



export class UserMap extends ApiResponseMap {
  public toDto(item: User): UserDto {
    return super.toDto(item) as UserDto;
  }

  public collectionToDto(
    items: User[],
  ): UserDto[] {
    return super.collectionToDto(items) as UserDto[];
  }
}
