import {Offer} from '../../interfaces/business';
import {ApiResponseMap} from '../apiResponse';

export interface OfferDto extends Offer {
  entity: string;
  iri: string;
}

export type OfferData = OfferDto


export class OfferMap extends ApiResponseMap {
  public toDto(item: Offer): OfferDto {
    return super.toDto(item) as OfferDto;
  }

  public collectionToDto(
    items: Offer[],
  ): OfferDto[] {
    return super.collectionToDto(items) as OfferDto[];
  }
}
